var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"accounting.credit_orders","resource":"/accounting/account/credit","enterable":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"order_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.transactions.length)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink(
              'order',
              item.transactions[0].receipts[0].receiptable_id
            ),"content":item.transactions[0].receipts[0].receiptable_id}}):_c('TMessageNotFound')],1)]}},{key:"customer",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":_vm.getCustomer(item),"noTranslate":""}})],1)]}},{key:"amount",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":item.wallet ? item.wallet.currency_id : ''}})],1)]}},{key:"outstanding_amount",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.outstanding_amount,"currency":item.wallet ? item.wallet.currency_id : ''}})],1)]}},{key:"paid_amount",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.paid_amount,"currency":item.wallet ? item.wallet.currency_id : ''}})],1)]}},{key:"payment_due_date",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.payment_due_date,"dateOnly":""}})],1)]}},{key:"payment_due_date-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setPaymentDueDateFilter]}})]},proxy:true},{key:"customer-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter['wallet.walletable_id'],"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'wallet.walletable_id', $event)},"change":_vm.selectWalletable}})]},proxy:true},{key:"order_id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.by_order,"placeholder":"Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "by_order", $event)},_vm.filterChange]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }